import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'


import Layout from './layout'

const Mentions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <Layout title="Mentions légales" svg="mentions">
      <Helmet title="Mentions légales"/>
      <div className="tucoco bisur container">
        <hr className="featurette-divider" />
        <h3>Editeur du site</h3>
        <p>
          <strong>LITTLE PRINCE EVENT</strong> <br /> 12 Allée du Grand Pavois{' '}
          <br /> 34200 Sete <br /> France <br /> Tél. : 06 33 39 15 24 <br />{' '}
          <a target="_blank" href="http://agencelads.com/">
            http://www.agencelads.com/
          </a>
        </p>
        <p>
          LITTLE PRINCE EVENT est une SARL au capital de 5000€ <br /> Siret :
          81284552700025 - APE : 9001Z{' '}
        </p>
        <h3>Conditions d'utilisation</h3>
        <p>
          Le site accessible par l'url suivant :{' '}
          <a target="_blank" href="https://web.agencelads.com/">
            https://web.agencelads.com/{' '}
          </a>
          est exploité dans le respect de la législation française.
          L'utilisation de ce site est régie par les présentes conditions
          générales. En utilisant le site, vous reconnaissez avoir pris
          connaissance de ces conditions et les avoir acceptées.
        </p>
        <h3>Responsable éditorial</h3>
        <p>
          Tom Sabatier <br />
          <strong>LITTLE PRINCE EVENT</strong> <br /> 12 Allée du Grand Pavois{' '}
          <br /> 34200 Sete <br /> France <br />
          <a target="_blank" href="http://agencelads.com/">
            http://www.agencelads.com/
          </a>
        </p>

        <h3>Propriété intellectuelle</h3>
        <p>
          Tout le contenu du présent site, incluant, de façon non limitative,
          les graphismes, images, textes, vidéos, animations, sons, logos, gifs
          et icônes ainsi que leur mise en forme sont la propriété exclusive de
          la société LITTLE PRINCE EVENT à l'exception des marques, logos ou
          contenus appartenant à d'autres sociétés partenaires ou auteurs. Toute
          reproduction, distribution, modification, adaptation, retransmission
          ou publication, même partielle, de ces différents éléments est
          strictement interdite sans l'accord exprès par écrit de LITTLE PRINCE
          EVENT . Cette représentation ou reproduction, par quelque procédé que
          ce soit, constitue une contrefaçon sanctionnée par les articles
          L.335-2 et suivants du Code de la propriété intellectuelle. Le
          non-respect de cette interdiction constitue une contrefaçon pouvant
          engager la responsabilité civile et pénale du contrefacteur. En outre,
          les propriétaires des Contenus copiés pourraient intenter une action
          en justice à votre encontre.
        </p>
        <p>
          LITTLE PRINCE EVENT est identiquement propriétaire des "droits des
          producteurs de bases de données" visés au Livre III, Titre IV, du Code
          de la Propriété Intellectuelle (loi n° 98-536 du 1er juillet 1998)
          relative aux droits d'auteur et aux bases de données.
        </p>

        <h3>Hébergeur</h3>
        <p>
          OVH, 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1 – France <br/> SAS au
          capital de 10 059 500,00 € <br/> RCS LILLE MÉTROPOLE 424 761 419 00045 <br/> Code
          APE 2620Z <br/>N°TVA FR FR22424761419
        
        </p>

        <h3>Confidentialité</h3>
        <p>
          Les données enregistrées sur ce site sont exclusivement destinées à
          Little Prince Event. Conformément à la loi Informatique et Libertés n°
          78-17 du 6 janvier 1978, vous disposez à tout moment d’un droit
          d’accès et de rectification aux données vous concernant. Vous pouvez
          accepter ou refuser l’utilisation de ces données par le service
          clients de l'Agence la DS. Pour cela, il vous suffit d’en faire la demande
          en précisant vos coordonnées, par courrier à <br />
          Agence la DS <br /> 12 Allée du Grand Pavois <br /> 34200 Sete
        </p>
      </div>
    </Layout>
  )
}

export default Mentions
